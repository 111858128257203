var render = function () {
  var _vm$$route$query, _vm$glossary, _vm$glossary2, _vm$$route$query2, _vm$glossary3, _vm$glossary4, _vm$glossary5, _vm$$route$query3, _vm$$route$query4, _vm$glossary6, _vm$glossary6$serving, _vm$glossary7, _vm$glossary7$serving, _vm$glossary8, _vm$glossary8$househo, _vm$glossary9, _vm$glossary9$househo, _vm$glossary10, _vm$glossary10$servin, _vm$glossary11, _vm$glossary11$househ, _vm$recalculateNutrit, _vm$recalculateNutrit2, _vm$glossary12, _vm$glossary12$nutrit, _vm$glossary12$nutrit2, _vm$recalculateNutrit3, _vm$recalculateNutrit4, _vm$glossary13, _vm$glossary13$nutrit, _vm$glossary13$nutrit2, _vm$glossary14, _vm$glossary14$nutrit, _vm$glossary14$nutrit2, _vm$glossary14$nutrit3, _vm$glossary15, _vm$glossary15$nutrit, _vm$glossary15$nutrit2, _vm$glossary15$nutrit3, _vm$recalculateNutrit5, _vm$recalculateNutrit6, _vm$glossary16, _vm$glossary16$nutrit, _vm$glossary16$nutrit2, _vm$glossary16$nutrit3, _vm$glossary17, _vm$glossary17$nutrit, _vm$glossary17$nutrit2, _vm$recalculateNutrit7, _vm$recalculateNutrit8, _vm$glossary21, _vm$glossary21$nutrit, _vm$glossary21$nutrit2, _vm$glossary21$nutrit3, _vm$glossary22, _vm$glossary22$nutrit, _vm$glossary22$nutrit2, _vm$glossary22$nutrit3, _vm$recalculateNutrit9, _vm$recalculateNutrit10, _vm$glossary23, _vm$glossary23$nutrit, _vm$glossary23$nutrit2, _vm$glossary23$nutrit3, _vm$glossary24, _vm$glossary24$nutrit, _vm$glossary24$nutrit2, _vm$recalculateNutrit11, _vm$recalculateNutrit12, _vm$recalculateNutrit13, _vm$recalculateNutrit14, _vm$glossary28, _vm$glossary28$nutrit, _vm$glossary28$nutrit2, _vm$recalculateNutrit15, _vm$recalculateNutrit16, _vm$glossary29, _vm$glossary29$nutrit, _vm$glossary29$nutrit2, _vm$recalculateNutrit17, _vm$recalculateNutrit18, _vm$glossary30, _vm$glossary30$nutrit, _vm$glossary30$nutrit2, _vm$recalculateNutrit19, _vm$glossary31, _vm$glossary31$other, _vm$glossary32, _vm$glossary32$other;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "gap": "48px",
      "margin-bottom": "24px"
    }
  }, [_vm.isRoleAdmin ? _c('c-button', {
    attrs: {
      "margin-top": "17.5px",
      "variant": "ghost",
      "display": "flex",
      "align-items": "center",
      "gap": "1rem"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('c-image', {
    attrs: {
      "w": "24px",
      "h": "24px",
      "object-fit": "cover",
      "src": require('@/assets/icon-chevron-left.svg')
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Kembali ")])], 1) : _vm._e(), _vm.isRoleAdmin ? _c('BreadcrumbPath', {
    attrs: {
      "paths": [{
        label: 'Manajemen Nilai Gizi',
        href: {
          name: 'admin.glossaries'
        }
      }, {
        label: 'Detail Nilai Gizi',
        isCurrent: true
      }]
    }
  }) : _vm._e(), !_vm.isRoleAdmin ? _c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbPath
    }
  })], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '20px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "max-width": "800px",
      "margin": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "20px"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": "28px",
      "font-weight": "700",
      "color": "#111"
    }
  }, [_vm._v(" Detail Nilai Gizi ")]), _vm.isRoleAdmin && !_vm.isOtherGlossary ? _c('c-flex', {
    attrs: {
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "danger",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "gap": "8px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalConfirmationDelete = true;
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-trash.svg'),
      "height": "20",
      "width": "20",
      "fill": "#FFF"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "gap": "8px"
    },
    on: {
      "click": function click() {
        _vm.$router.push({
          name: 'admin.glossaries-edit',
          params: {
            glossaryId: _vm.$route.params.glossaryId
          }
        });
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-edit-2.svg'),
      "height": "20",
      "width": "20",
      "fill": "#FFF"
    }
  }), _vm._v(" Edit ")], 1)], 1) : _vm._e()], 1), _c('c-text', {
    attrs: {
      "display": ['block', 'none'],
      "font-size": "20px",
      "font-weight": "700",
      "text-align": "center",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.isOtherGlossary ? ((_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.name) || '-' : ((_vm$glossary = _vm.glossary) === null || _vm$glossary === void 0 ? void 0 : _vm$glossary.foodName) || '-') + " ")]), _c('c-flex', {
    attrs: {
      "gap": "32px",
      "flex-direction": ['column', 'row'],
      "justi": ""
    }
  }, [_c('c-box', {
    on: {
      "click": function click($event) {
        _vm.isOpenModalImage = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "cursor": "pointer",
      "src": _vm.isOtherGlossary ? 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250' : _vm.getPhotoBlankGray((_vm$glossary2 = _vm.glossary) === null || _vm$glossary2 === void 0 ? void 0 : _vm$glossary2.photoUrl),
      "border-radius": "12px",
      "height": ['100%', '200px'],
      "width": ['100%', '200px'],
      "object-fit": "cover"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": "20px",
      "font-weight": "700"
    }
  }, [_vm._v(" " + _vm._s(_vm.isOtherGlossary ? ((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.name) || '-' : ((_vm$glossary3 = _vm.glossary) === null || _vm$glossary3 === void 0 ? void 0 : _vm$glossary3.foodName) || '-') + " ")]), _c('c-text', {
    attrs: {
      "margin-top": ['0', '16px'],
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Golongan Makanan ")]), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary4 = _vm.glossary) === null || _vm$glossary4 === void 0 ? void 0 : _vm$glossary4.foodGroup) || '-') + " ")]), _c('c-text', {
    attrs: {
      "margin-top": ['24px', '8px'],
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Nama Bahan Dasar ")]), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary5 = _vm.glossary) === null || _vm$glossary5 === void 0 ? void 0 : _vm$glossary5.basicFoodIngredient) || '-') + " ")])], 1)], 1), _vm.isOtherGlossary ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#00A68C",
      "text-color": "neutral.white",
      "font-size": ['16px', '18px'],
      "padding": "10px",
      "font-weight": "500",
      "gap": "5px",
      "margin-block": "20px"
    }
  }, [_vm._v(" Ukuran/Takaran Saji "), _c('c-box', {
    attrs: {
      "as": "span"
    }
  }, [_vm._v(" " + _vm._s((_vm$$route$query3 = _vm.$route.query) === null || _vm$$route$query3 === void 0 ? void 0 : _vm$$route$query3.servingSize) + " " + _vm._s((_vm$$route$query4 = _vm.$route.query) === null || _vm$$route$query4 === void 0 ? void 0 : _vm$$route$query4.servingSizeUnit) + " ")])], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "align-items": "center",
      "gap": "20px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/images/image-undraw-advanced-customization.svg'),
      "width": ['200px', '250px']
    }
  }), _c('c-box', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": ['14px', '16px'],
      "font-weight": "500",
      "text-align": "center"
    }
  }, [_c('c-text', [_vm._v(" Ahli gizi belum memilih tampilan ")]), _c('c-text', [_vm._v(" Akses menu Nilai Gizi untuk info gizi bahan makanan ")])], 1), _c('BaseButton', {
    attrs: {
      "color": "primary",
      "rounded": "1000px",
      "width": "100%",
      "max-width": ['200px', '350px'],
      "variant": "solid"
    },
    on: {
      "click": _vm.onClickListGlossaries
    }
  }, [_vm._v(" Akses Nilai Gizi ")])], 1)], 1) : _c('c-box', [_vm.listFoodIngridients.length >= 1 ? _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "gap": "3px"
    }
  }, [_c('c-text', {
    attrs: {
      "margin-top": ['24px', '32px'],
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Komposi (Per porsi) ")]), _c('c-box', {
    attrs: {
      "as": "ul",
      "padding-left": "26px"
    }
  }, _vm._l(_vm.listFoodIngridients || [], function (item, index) {
    return _c('c-text', {
      key: index,
      attrs: {
        "as": "li",
        "font-weight": "500",
        "font-size": ['14px', '18px']
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.isRoleAdmin || _vm.isRoleNutritionist ? _c('c-flex', {
    attrs: {
      "width": "100%",
      "background-color": "#00947D",
      "border-radius": "10px",
      "padding": "12px",
      "margin-top": "32px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "align-items": "center",
      "color": "white"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "text-align": "center"
    }
  }, [_vm._v(" Takaran Saji/Porsi ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary6 = _vm.glossary) === null || _vm$glossary6 === void 0 ? void 0 : (_vm$glossary6$serving = _vm$glossary6.servingSizePerPortion) === null || _vm$glossary6$serving === void 0 ? void 0 : _vm$glossary6$serving.servingSizePerPortion) || '-') + " " + _vm._s(((_vm$glossary7 = _vm.glossary) === null || _vm$glossary7 === void 0 ? void 0 : (_vm$glossary7$serving = _vm$glossary7.servingSizePerPortion) === null || _vm$glossary7$serving === void 0 ? void 0 : _vm$glossary7$serving.servingSizeUnitPerPortion) || '-') + " ")])], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "align-items": "center",
      "color": "white"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "text-align": "center"
    }
  }, [_vm._v(" Ukuran Rumah Tanggal/Porsi ")]), _c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.convertToFraction((_vm$glossary8 = _vm.glossary) === null || _vm$glossary8 === void 0 ? void 0 : (_vm$glossary8$househo = _vm$glossary8.householdMeasurementPerPortion) === null || _vm$glossary8$househo === void 0 ? void 0 : _vm$glossary8$househo.householdMeasurementPerPortion) || '-') + " " + _vm._s(((_vm$glossary9 = _vm.glossary) === null || _vm$glossary9 === void 0 ? void 0 : (_vm$glossary9$househo = _vm$glossary9.householdMeasurementPerPortion) === null || _vm$glossary9$househo === void 0 ? void 0 : _vm$glossary9$househo.householdMeasurementUnitPerPortion) || '-') + " ")])], 1)], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "gap": ['0', '16px'],
      "margin-top": "32px",
      "flex-direction": ['column', 'row']
    }
  }, [_c('BaseInputText', {
    attrs: {
      "value": _vm.glossary.servingSize.servingSize,
      "label": "Takaran Saji",
      "placeholder": "Masukkan Takaran Saji",
      "full-width": "",
      "input-right-addon": ((_vm$glossary10 = _vm.glossary) === null || _vm$glossary10 === void 0 ? void 0 : (_vm$glossary10$servin = _vm$glossary10.servingSize) === null || _vm$glossary10$servin === void 0 ? void 0 : _vm$glossary10$servin.servingSizeUnit) || '-',
      "is-invalid": _vm.isInvalidField(_vm.$v.glossary.servingSize.servingSize),
      "invalid-text": _vm.parseErrors('Takaran Saji', _vm.$v.glossary.servingSize.servingSize)
    },
    on: {
      "input": function input(value) {
        return _vm.onInputServingSize(value);
      },
      "blur": _vm.$v.glossary.servingSize.servingSize.$touch
    }
  }), _c('BaseInputText', {
    attrs: {
      "value": _vm.glossary.householdMeasurement.householdMeasurement,
      "label": "Ukuran Rumah Tangga",
      "placeholder": "Masukkan Ukuran Rumah Tangga",
      "full-width": "",
      "input-right-addon": ((_vm$glossary11 = _vm.glossary) === null || _vm$glossary11 === void 0 ? void 0 : (_vm$glossary11$househ = _vm$glossary11.householdMeasurement) === null || _vm$glossary11$househ === void 0 ? void 0 : _vm$glossary11$househ.householdMeasurementUnit) || '-',
      "is-invalid": _vm.isInvalidField(_vm.$v.glossary.householdMeasurement.householdMeasurement),
      "invalid-text": _vm.parseErrors('Ukuran Rumah Tangga', _vm.$v.glossary.householdMeasurement.householdMeasurement),
      "is-centered": ""
    },
    on: {
      "input": function input(value) {
        return _vm.onInputHouseHoldMeasurement(value);
      },
      "blur": _vm.$v.glossary.householdMeasurement.householdMeasurement.$touch
    },
    scopedSlots: _vm._u([{
      key: "input-left-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-right-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.glossary.householdMeasurement.householdMeasurement) ? 'danger.400' : 'inherit',
            "border-top-left-radius": "6px",
            "border-bottom-left-radius": "6px",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(-0.25);
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-minus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.glossary.householdMeasurement.householdMeasurement) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "input-right-icon-element",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "as": "button",
            "type": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-left-width": "1px",
            "border-style": "solid",
            "border-color": _vm.isInvalidField(_vm.$v.glossary.householdMeasurement.householdMeasurement) ? 'danger.400' : 'inherit',
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onUpdateHouseholdMeasurement(0.25);
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.isInvalidField(_vm.$v.glossary.householdMeasurement.householdMeasurement) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('c-box', {
    attrs: {
      "margin": "20px 0"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "table",
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "tr",
      "background-color": "#00A68C"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "10px 16px",
      "border-top-left-radius": "8px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "color": "white"
    }
  }, [_vm._v(" Informasi Nilai Gizi ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "colspan": "2",
      "padding": "10px 16px",
      "border-top-right-radius": "8px",
      "width": "1%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "flex-end"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "color": "primary.400",
      "text-align": "end",
      "background-color": "white",
      "padding": "4px 6px",
      "border-radius": "6px",
      "white-space": "nowrap",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" " + _vm._s(_vm.recalculateNutrition.description || '0') + " ")])], 1)], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" Energi ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit = _vm.recalculateNutrition) === null || _vm$recalculateNutrit === void 0 ? void 0 : (_vm$recalculateNutrit2 = _vm$recalculateNutrit.calories) === null || _vm$recalculateNutrit2 === void 0 ? void 0 : _vm$recalculateNutrit2.calories) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary12 = _vm.glossary) === null || _vm$glossary12 === void 0 ? void 0 : (_vm$glossary12$nutrit = _vm$glossary12.nutritionContent) === null || _vm$glossary12$nutrit === void 0 ? void 0 : (_vm$glossary12$nutrit2 = _vm$glossary12$nutrit.calories) === null || _vm$glossary12$nutrit2 === void 0 ? void 0 : _vm$glossary12$nutrit2.unit) || '-') + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Protein ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit3 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit3 === void 0 ? void 0 : (_vm$recalculateNutrit4 = _vm$recalculateNutrit3.protein) === null || _vm$recalculateNutrit4 === void 0 ? void 0 : _vm$recalculateNutrit4.protein) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary13 = _vm.glossary) === null || _vm$glossary13 === void 0 ? void 0 : (_vm$glossary13$nutrit = _vm$glossary13.nutritionContent) === null || _vm$glossary13$nutrit === void 0 ? void 0 : (_vm$glossary13$nutrit2 = _vm$glossary13$nutrit.protein) === null || _vm$glossary13$nutrit2 === void 0 ? void 0 : _vm$glossary13$nutrit2.unit) || '-') + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": ((_vm$glossary14 = _vm.glossary) === null || _vm$glossary14 === void 0 ? void 0 : (_vm$glossary14$nutrit = _vm$glossary14.nutritionContent) === null || _vm$glossary14$nutrit === void 0 ? void 0 : (_vm$glossary14$nutrit2 = _vm$glossary14$nutrit.fat) === null || _vm$glossary14$nutrit2 === void 0 ? void 0 : (_vm$glossary14$nutrit3 = _vm$glossary14$nutrit2.sub) === null || _vm$glossary14$nutrit3 === void 0 ? void 0 : _vm$glossary14$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Lemak ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": ((_vm$glossary15 = _vm.glossary) === null || _vm$glossary15 === void 0 ? void 0 : (_vm$glossary15$nutrit = _vm$glossary15.nutritionContent) === null || _vm$glossary15$nutrit === void 0 ? void 0 : (_vm$glossary15$nutrit2 = _vm$glossary15$nutrit.fat) === null || _vm$glossary15$nutrit2 === void 0 ? void 0 : (_vm$glossary15$nutrit3 = _vm$glossary15$nutrit2.sub) === null || _vm$glossary15$nutrit3 === void 0 ? void 0 : _vm$glossary15$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit5 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit5 === void 0 ? void 0 : (_vm$recalculateNutrit6 = _vm$recalculateNutrit5.fat) === null || _vm$recalculateNutrit6 === void 0 ? void 0 : _vm$recalculateNutrit6.fat) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": ((_vm$glossary16 = _vm.glossary) === null || _vm$glossary16 === void 0 ? void 0 : (_vm$glossary16$nutrit = _vm$glossary16.nutritionContent) === null || _vm$glossary16$nutrit === void 0 ? void 0 : (_vm$glossary16$nutrit2 = _vm$glossary16$nutrit.fat) === null || _vm$glossary16$nutrit2 === void 0 ? void 0 : (_vm$glossary16$nutrit3 = _vm$glossary16$nutrit2.sub) === null || _vm$glossary16$nutrit3 === void 0 ? void 0 : _vm$glossary16$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary17 = _vm.glossary) === null || _vm$glossary17 === void 0 ? void 0 : (_vm$glossary17$nutrit = _vm$glossary17.nutritionContent) === null || _vm$glossary17$nutrit === void 0 ? void 0 : (_vm$glossary17$nutrit2 = _vm$glossary17$nutrit.fat) === null || _vm$glossary17$nutrit2 === void 0 ? void 0 : _vm$glossary17$nutrit2.unit) || '-') + " ")])], 1)], 1), _vm._l(((_vm$recalculateNutrit7 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit7 === void 0 ? void 0 : (_vm$recalculateNutrit8 = _vm$recalculateNutrit7.fat) === null || _vm$recalculateNutrit8 === void 0 ? void 0 : _vm$recalculateNutrit8.sub) || [], function (item, index) {
    var _vm$glossary18, _vm$glossary18$nutrit, _vm$glossary18$nutrit2, _vm$glossary18$nutrit3, _vm$glossary19, _vm$glossary19$nutrit, _vm$glossary19$nutrit2, _vm$glossary19$nutrit3, _vm$glossary20, _vm$glossary20$nutrit, _vm$glossary20$nutrit2, _vm$glossary20$nutrit3;

    return _c('c-box', {
      key: index,
      attrs: {
        "as": "tr"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "td",
        "padding": "6px 16px 0 16px",
        "width": "100%",
        "colspan": "3"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "table",
        "width": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "tr"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "td",
        "padding": "0 0 0 16px",
        "width": "100%",
        "border-bottom": ((_vm$glossary18 = _vm.glossary) === null || _vm$glossary18 === void 0 ? void 0 : (_vm$glossary18$nutrit = _vm$glossary18.nutritionContent) === null || _vm$glossary18$nutrit === void 0 ? void 0 : (_vm$glossary18$nutrit2 = _vm$glossary18$nutrit.fat) === null || _vm$glossary18$nutrit2 === void 0 ? void 0 : (_vm$glossary18$nutrit3 = _vm$glossary18$nutrit2.sub) === null || _vm$glossary18$nutrit3 === void 0 ? void 0 : _vm$glossary18$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "padding-bottom": "6px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.name) || '-') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "1%",
        "padding": "0 0 0 0",
        "border-bottom": ((_vm$glossary19 = _vm.glossary) === null || _vm$glossary19 === void 0 ? void 0 : (_vm$glossary19$nutrit = _vm$glossary19.nutritionContent) === null || _vm$glossary19$nutrit === void 0 ? void 0 : (_vm$glossary19$nutrit2 = _vm$glossary19$nutrit.fat) === null || _vm$glossary19$nutrit2 === void 0 ? void 0 : (_vm$glossary19$nutrit3 = _vm$glossary19$nutrit2.sub) === null || _vm$glossary19$nutrit3 === void 0 ? void 0 : _vm$glossary19$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "white-space": "nowrap",
        "text-align": "end",
        "padding-bottom": "6px",
        "padding-right": "16px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.amount) || '0') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "0.01%",
        "padding": "0 16px 0 0",
        "border-bottom": ((_vm$glossary20 = _vm.glossary) === null || _vm$glossary20 === void 0 ? void 0 : (_vm$glossary20$nutrit = _vm$glossary20.nutritionContent) === null || _vm$glossary20$nutrit === void 0 ? void 0 : (_vm$glossary20$nutrit2 = _vm$glossary20$nutrit.fat) === null || _vm$glossary20$nutrit2 === void 0 ? void 0 : (_vm$glossary20$nutrit3 = _vm$glossary20$nutrit2.sub) === null || _vm$glossary20$nutrit3 === void 0 ? void 0 : _vm$glossary20$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "white-space": "nowrap",
        "text-align": "end",
        "padding-bottom": "6px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.unit) || '-') + " ")])], 1)], 1)], 1)], 1)], 1);
  }), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": ((_vm$glossary21 = _vm.glossary) === null || _vm$glossary21 === void 0 ? void 0 : (_vm$glossary21$nutrit = _vm$glossary21.nutritionContent) === null || _vm$glossary21$nutrit === void 0 ? void 0 : (_vm$glossary21$nutrit2 = _vm$glossary21$nutrit.carbohydrate) === null || _vm$glossary21$nutrit2 === void 0 ? void 0 : (_vm$glossary21$nutrit3 = _vm$glossary21$nutrit2.sub) === null || _vm$glossary21$nutrit3 === void 0 ? void 0 : _vm$glossary21$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Karbohidrat ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": ((_vm$glossary22 = _vm.glossary) === null || _vm$glossary22 === void 0 ? void 0 : (_vm$glossary22$nutrit = _vm$glossary22.nutritionContent) === null || _vm$glossary22$nutrit === void 0 ? void 0 : (_vm$glossary22$nutrit2 = _vm$glossary22$nutrit.carbohydrate) === null || _vm$glossary22$nutrit2 === void 0 ? void 0 : (_vm$glossary22$nutrit3 = _vm$glossary22$nutrit2.sub) === null || _vm$glossary22$nutrit3 === void 0 ? void 0 : _vm$glossary22$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit9 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit9 === void 0 ? void 0 : (_vm$recalculateNutrit10 = _vm$recalculateNutrit9.carbohydrate) === null || _vm$recalculateNutrit10 === void 0 ? void 0 : _vm$recalculateNutrit10.carbohydrate) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": ((_vm$glossary23 = _vm.glossary) === null || _vm$glossary23 === void 0 ? void 0 : (_vm$glossary23$nutrit = _vm$glossary23.nutritionContent) === null || _vm$glossary23$nutrit === void 0 ? void 0 : (_vm$glossary23$nutrit2 = _vm$glossary23$nutrit.carbohydrate) === null || _vm$glossary23$nutrit2 === void 0 ? void 0 : (_vm$glossary23$nutrit3 = _vm$glossary23$nutrit2.sub) === null || _vm$glossary23$nutrit3 === void 0 ? void 0 : _vm$glossary23$nutrit3.length) >= 1 ? '' : '1px solid #C4C4C4',
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary24 = _vm.glossary) === null || _vm$glossary24 === void 0 ? void 0 : (_vm$glossary24$nutrit = _vm$glossary24.nutritionContent) === null || _vm$glossary24$nutrit === void 0 ? void 0 : (_vm$glossary24$nutrit2 = _vm$glossary24$nutrit.carbohydrate) === null || _vm$glossary24$nutrit2 === void 0 ? void 0 : _vm$glossary24$nutrit2.unit) || '-') + " ")])], 1)], 1), _vm._l(((_vm$recalculateNutrit11 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit11 === void 0 ? void 0 : (_vm$recalculateNutrit12 = _vm$recalculateNutrit11.carbohydrate) === null || _vm$recalculateNutrit12 === void 0 ? void 0 : _vm$recalculateNutrit12.sub) || [], function (item, index) {
    var _vm$glossary25, _vm$glossary25$nutrit, _vm$glossary25$nutrit2, _vm$glossary25$nutrit3, _vm$glossary26, _vm$glossary26$nutrit, _vm$glossary26$nutrit2, _vm$glossary26$nutrit3, _vm$glossary27, _vm$glossary27$nutrit, _vm$glossary27$nutrit2, _vm$glossary27$nutrit3;

    return _c('c-box', {
      key: index + 99,
      attrs: {
        "as": "tr"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "td",
        "padding": "6px 16px 0 16px",
        "width": "100%",
        "colspan": "3"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "table",
        "width": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "tr"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "td",
        "padding": "0 0 0 16px",
        "width": "100%",
        "border-bottom": ((_vm$glossary25 = _vm.glossary) === null || _vm$glossary25 === void 0 ? void 0 : (_vm$glossary25$nutrit = _vm$glossary25.nutritionContent) === null || _vm$glossary25$nutrit === void 0 ? void 0 : (_vm$glossary25$nutrit2 = _vm$glossary25$nutrit.carbohydrate) === null || _vm$glossary25$nutrit2 === void 0 ? void 0 : (_vm$glossary25$nutrit3 = _vm$glossary25$nutrit2.sub) === null || _vm$glossary25$nutrit3 === void 0 ? void 0 : _vm$glossary25$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "padding-bottom": "6px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.name) || '-') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "1%",
        "padding": "0 0 0 0",
        "border-bottom": ((_vm$glossary26 = _vm.glossary) === null || _vm$glossary26 === void 0 ? void 0 : (_vm$glossary26$nutrit = _vm$glossary26.nutritionContent) === null || _vm$glossary26$nutrit === void 0 ? void 0 : (_vm$glossary26$nutrit2 = _vm$glossary26$nutrit.carbohydrate) === null || _vm$glossary26$nutrit2 === void 0 ? void 0 : (_vm$glossary26$nutrit3 = _vm$glossary26$nutrit2.sub) === null || _vm$glossary26$nutrit3 === void 0 ? void 0 : _vm$glossary26$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "white-space": "nowrap",
        "text-align": "end",
        "padding-bottom": "6px",
        "padding-right": "16px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.amount) || '0') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "0.01%",
        "padding": "0 16px 0 0",
        "border-bottom": ((_vm$glossary27 = _vm.glossary) === null || _vm$glossary27 === void 0 ? void 0 : (_vm$glossary27$nutrit = _vm$glossary27.nutritionContent) === null || _vm$glossary27$nutrit === void 0 ? void 0 : (_vm$glossary27$nutrit2 = _vm$glossary27$nutrit.carbohydrate) === null || _vm$glossary27$nutrit2 === void 0 ? void 0 : (_vm$glossary27$nutrit3 = _vm$glossary27$nutrit2.sub) === null || _vm$glossary27$nutrit3 === void 0 ? void 0 : _vm$glossary27$nutrit3.length) - 1 === index ? '1px solid #C4C4C4' : ''
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "white-space": "nowrap",
        "text-align": "end",
        "padding-bottom": "6px",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.unit) || '-') + " ")])], 1)], 1)], 1)], 1)], 1);
  }), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Kolestrol ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit13 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit13 === void 0 ? void 0 : (_vm$recalculateNutrit14 = _vm$recalculateNutrit13.cholesterol) === null || _vm$recalculateNutrit14 === void 0 ? void 0 : _vm$recalculateNutrit14.cholesterol) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary28 = _vm.glossary) === null || _vm$glossary28 === void 0 ? void 0 : (_vm$glossary28$nutrit = _vm$glossary28.nutritionContent) === null || _vm$glossary28$nutrit === void 0 ? void 0 : (_vm$glossary28$nutrit2 = _vm$glossary28$nutrit.cholesterol) === null || _vm$glossary28$nutrit2 === void 0 ? void 0 : _vm$glossary28$nutrit2.unit) || '-') + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Natrium (garam) ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit15 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit15 === void 0 ? void 0 : (_vm$recalculateNutrit16 = _vm$recalculateNutrit15.natrium) === null || _vm$recalculateNutrit16 === void 0 ? void 0 : _vm$recalculateNutrit16.natrium) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary29 = _vm.glossary) === null || _vm$glossary29 === void 0 ? void 0 : (_vm$glossary29$nutrit = _vm$glossary29.nutritionContent) === null || _vm$glossary29$nutrit === void 0 ? void 0 : (_vm$glossary29$nutrit2 = _vm$glossary29$nutrit.natrium) === null || _vm$glossary29$nutrit2 === void 0 ? void 0 : _vm$glossary29$nutrit2.unit) || '-') + " ")])], 1)], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "td",
      "padding": "20px 0 0 16px",
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" Zat Besi ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "1%",
      "padding": "20px 0 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px",
      "padding-right": "16px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$recalculateNutrit17 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit17 === void 0 ? void 0 : (_vm$recalculateNutrit18 = _vm$recalculateNutrit17.iron) === null || _vm$recalculateNutrit18 === void 0 ? void 0 : _vm$recalculateNutrit18.iron) || '0') + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "td",
      "width": "0.01%",
      "padding": "20px 16px 0 0"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "white-space": "nowrap",
      "text-align": "end",
      "border-bottom": "1px solid #C4C4C4",
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary30 = _vm.glossary) === null || _vm$glossary30 === void 0 ? void 0 : (_vm$glossary30$nutrit = _vm$glossary30.nutritionContent) === null || _vm$glossary30$nutrit === void 0 ? void 0 : (_vm$glossary30$nutrit2 = _vm$glossary30$nutrit.iron) === null || _vm$glossary30$nutrit2 === void 0 ? void 0 : _vm$glossary30$nutrit2.unit) || '-') + " ")])], 1)], 1), _vm._l(((_vm$recalculateNutrit19 = _vm.recalculateNutrition) === null || _vm$recalculateNutrit19 === void 0 ? void 0 : _vm$recalculateNutrit19.additonalNutritionContent) || [], function (item, index) {
    return _c('c-box', {
      key: index + 888,
      attrs: {
        "as": "tr"
      }
    }, [_c('c-box', {
      attrs: {
        "as": "td",
        "padding": "20px 0 0 16px",
        "width": "100%"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '18px'],
        "font-weight": "500",
        "border-bottom": "1px solid #C4C4C4",
        "padding-bottom": "6px"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.name) || '-') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "1%",
        "padding": "20px 0 0 0"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '18px'],
        "font-weight": "500",
        "white-space": "nowrap",
        "text-align": "end",
        "border-bottom": "1px solid #C4C4C4",
        "padding-bottom": "6px",
        "padding-right": "16px"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.amount) || '0') + " ")])], 1), _c('c-box', {
      attrs: {
        "as": "td",
        "width": "0.01%",
        "padding": "20px 16px 0 0"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": ['14px', '18px'],
        "font-weight": "500",
        "white-space": "nowrap",
        "text-align": "end",
        "border-bottom": "1px solid #C4C4C4",
        "padding-bottom": "6px"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.unit) || '-') + " ")])], 1)], 1);
  })], 2)], 1), _c('c-box', {
    attrs: {
      "margin": "20px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": ['18px', '20px'],
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Zat Gizi Dominan ")]), _c('c-box', {
    attrs: {
      "padding-inline": "16px"
    }
  }, [_c('c-grid', {
    attrs: {
      "template-columns": ['repeat(auto-fill, minmax(75px, 1fr))', 'repeat(auto-fill, minmax(130px, 1fr))'],
      "gap": "3"
    }
  }, _vm._l(_vm.mainNutritionContent || [], function (item, index) {
    return _c('c-aspect-ratio-box', {
      key: index,
      attrs: {
        "ratio": 1,
        "position": "relative"
      }
    }, [_c('c-flex', {
      attrs: {
        "border": "1.5px solid #FDEDCB",
        "border-radius": "8px",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "width": "100%",
        "height": "100%",
        "background-color": "#FFFCDD"
      }
    }, [_c('c-image', {
      attrs: {
        "src": _vm.getPhotoBlankGray(item === null || item === void 0 ? void 0 : item.photoUrl),
        "alt": "icon zat gizi dominan",
        "width": ['30px', '50px'],
        "height": ['30px', '50px']
      }
    }), _c('c-text', {
      attrs: {
        "position": "absolute",
        "top": "10px",
        "left": "15px",
        "font-size": ['14px', '18px'],
        "font-weight": "500",
        "color": "primary.400"
      }
    }, [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('c-text', {
      attrs: {
        "font-size": ['14px', '16px'],
        "font-weight": "400",
        "color": "primary.400",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : item.value) || '-') + " ")])], 1)], 1);
  }), 1)], 1), _c('c-box', {
    attrs: {
      "margin": "40px 0 20px 0"
    }
  }, [_c('c-text', {
    attrs: {
      "width": "100%",
      "padding": "10px 16px",
      "border-bottom": "1px solid #888",
      "font-size": ['18px', '20px'],
      "color": "primary.400",
      "font-weight": "700",
      "margin-bottom": "16px"
    }
  }, [_vm._v(" Lainnya ")]), _c('c-grid', {
    attrs: {
      "w": "100%",
      "padding-inline": "16px",
      "template-columns": ['repeat(1, 1fr)'],
      "gap": ['20px'],
      "mx": "auto"
    }
  }, [_c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Makanan Khas ")]), _c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary31 = _vm.glossary) === null || _vm$glossary31 === void 0 ? void 0 : (_vm$glossary31$other = _vm$glossary31.other) === null || _vm$glossary31$other === void 0 ? void 0 : _vm$glossary31$other.cuisine) || '-') + " ")])], 1), _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "primary.400"
    }
  }, [_vm._v(" Makanan ")]), _c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "#333"
    }
  }, [_vm._v(" " + _vm._s(((_vm$glossary32 = _vm.glossary) === null || _vm$glossary32 === void 0 ? void 0 : (_vm$glossary32$other = _vm$glossary32.other) === null || _vm$glossary32$other === void 0 ? void 0 : _vm$glossary32$other.tag) || '-') + " ")])], 1)], 1)], 1)], 1)], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalConfirmationDelete,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-delete.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$glossary33;

        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Anda yakin ingin hapus data nilai gizi “" + _vm._s((_vm$glossary33 = _vm.glossary) === null || _vm$glossary33 === void 0 ? void 0 : _vm$glossary33.foodName) + "”? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalConfirmationDelete = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-trash.svg'),
            "right-svg-icon-color": "white",
            "color": "danger",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isDeleting
          },
          on: {
            "click": _vm.onDelete
          }
        }, [_vm._v(" Hapus ")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessDelete,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "width": "150px",
            "object-fit": "cover",
            "src": require('@/assets/images/success-image.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Data nilai gizi berhasil dihapus! ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalSuccessDelete = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalImage,
      "close-on-overlay-click": "",
      "with-button-close": "",
      "has-header": false,
      "has-footer": false,
      "size": "xl"
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalImage = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$glossary34;

        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_c('c-image', {
          attrs: {
            "src": _vm.getPhotoBlankGray((_vm$glossary34 = _vm.glossary) === null || _vm$glossary34 === void 0 ? void 0 : _vm$glossary34.photoUrl)
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }