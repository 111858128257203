export const reqMealGlossaries_getDetail = async(axios, { id }) => {
  return axios.get(`/v1/admin/meal-glossaries/${id}`)
}
export const reqMealGlossaries_ClientNutritionist_getDetail = async(axios, { id }) => {
  return axios.get(`/v1/meal-glossaries/${id}`)
}
export const reqMealGlossaries_edit = async(axios, { id }, payload) => {
  return axios.put(`/v1/admin/meal-glossaries/${id}`, payload)
}
export const reqMealGlossaries_create = async(axios, payload) => {
  return axios.post('/v1/admin/meal-glossaries', payload)
}

export const reqMealGlossaries_delete = async(axios, { ids }) => {
  return axios.delete('/v1/admin/meal-glossaries', {
    data: {
      id: ids,
    },
  })
}

export const reqMealGlossaries_getListOptionsBasicIngredient = async(axios) => {
  return axios.get('/v1/admin/meal-glossaries-support')
}
